import { useEffect } from "react";

const TopScroll = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return <></>
}

export default TopScroll;