import React from "react";

const UseOfYourPersonalData = () => {
    return <div>
        <h2 className="text-[1.1rem] lg:text-[1.8rem] mb-5">Use of Your Personal Data</h2>
        <p>
            The Initiative may use Personal Data for the following purposes:
        </p>

        <ul className="pl-5 py-5 list-disc">
            <li className="py-2">
                To provide and maintain our Service, including to monitor the usage of our Service
            </li>
            <li className="py-2">
                <span className="text-blue-400 font-semibold">To manage Your Account</span>: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.
            </li>
            <li className="py-2">
                <span className="text-blue-400 font-semibold">For the performance of a contract</span>: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.
            </li>
            <li className="py-2">
                <span className="text-blue-400 font-semibold">To contact You</span>: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.
            </li>
            <li className="py-2">
                To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.
            </li>
            <li className="py-2">
                <span className="text-blue-400 font-semibold">To manage Your requests</span>: To attend and manage Your requests to Us.
            </li>
            <li className="py-2">
                <span className="text-blue-400 font-semibold">For business transfers</span>: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.
            </li>
            <li className="py-2">
                <span className="text-blue-400 font-semibold">For other purposes</span>: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.
            </li>
        </ul>

        <p>
            We may share Your personal information in the following situations:
        </p>

        <ul className="pl-5 py-5 list-disc">
            <li className="py-2">
                <span className="text-blue-400 font-semibold">With Service Providers</span>: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.
            </li>
            <li className="py-2">
                <span className="text-blue-400 font-semibold">For business transfers</span>: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Initiative assets, financing, or acquisition of all or a portion of Our business to another company.
            </li>
            <li className="py-2">
                <span className="text-blue-400 font-semibold">With Affiliates</span>: We may share Your information with trusted affiliates who provide services for us, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.
            </li>
            <li className="py-2">
               <span className="text-blue-400 font-semibold">With business partners</span>: We may share Your information with Our business partners to offer You certain products, services or promotions.
            </li>
            <li className="py-2">
                <span className="text-blue-400 font-semibold">With other users</span>: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.            
            </li>
            <li className="py-2">
                <span className="text-blue-400 font-semibold">With Your consent</span>: We may disclose Your personal information for any other purpose with Your consent.
            </li>
        </ul>
    </div>
}

export default UseOfYourPersonalData;