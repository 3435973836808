const TypesOfDataCollected = () => {
    return <div>
        <h2 className="text-[1.1rem] lg:text-[1.8rem] mb-5">Types of Data Collected</h2>

        {/* Personal Data */}
        <h2 className="text-[1rem] lg:text-[1.6rem] mb-5">Personal Data</h2>
        <p className="mb-5">
            While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You.
            Personally identifiable information may include, but is not limited to:
        </p>
        <div className="pl-5">
            <ul className="list-disc">
                <li className="py-2">Email Address</li>
                <li className="py-2">First name and last name</li>
                <li className="py-2">Usage Data</li>
            </ul>
        </div>
        
        {/* Usage Data */}
        <h2 className="text-[1rem] lg:text-[1.6rem] my-5">Usage Data</h2>
        <p>
            Usage Data is collected automatically when using the Service.
            Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
            When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
            We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
        </p>

        {/* Information from Third-Party Social Media Services */}
        <h2 className="text-[1rem] lg:text-[1.6rem] my-5">Information from Third-Party Social Media Services</h2>
        <p>
            Any and all data from Third-Party Social Media Services is only collected when You have given the Serivce permission to access it. 
            The Initiative allows You to create an account and log in to use the Service through the following Third-party Social Media Services:
        </p>
        <ul className="pl-5 py-5 list-disc">
            <li className="py-2">Google</li>
            <li className="py-2">Facebook</li>
            <li className="py-2">Twitter</li>
            <li className="py-2">Instagram</li>
        </ul>
        <p>
            If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities or Your contact list associated with that account.
            You may also have the option of sharing additional information with the Initiative through Your Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving the Initiative permission to use, share, and store it in a manner consistent with this Privacy Policy.
        </p>

        {/* Tracking Technologies and Cookies */}
        <h2 className="text-[1rem] lg:text-[1.6rem] my-5">Tracking Technologies and Cookies</h2>
        <p>
            We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information.
            Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service.
            The technologies We use may include:
        </p>
        <ul className="pl-5 py-5 list-disc">
            <li className="py-2">
                <span className="text-blue-400 font-semibold">Cookies or Browser Cookies</span>.
                A cookie is a small file placed on Your Device.
                You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent.
                However, if You do not accept Cookies, You may not be able to use some parts of our Service.
                Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.
            </li>
            <li className="py-2">
                <span className="text-blue-400 font-semibold">Flash Cookies</span>.
                Certain features of our Service may use local stored objects (or Flash Cookies) to collect and store information about Your preferences or Your activity on our Service.
                Flash Cookies are not managed by the same browser settings as those used for Browser Cookies.
                For more information on how You can delete Flash Cookies, please read "Where can I change the settings for disabling, or deleting local shared objects?" available at <a href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_" className="text-green-400 hover:text-blue-300" rel="noreferrer">https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_</a>
            </li>
            <li className="py-2">
                <span className="text-blue-400 font-semibold">Web Beacons</span>.
                Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Initiative, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).
            </li>
        </ul>
        <p>
            Cookies can be "Persistent" or "Session" Cookies.
            Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.
            We use both Session and Persistent Cookies for the purposes set out below:
        </p>
    </div>
}

export default TypesOfDataCollected;