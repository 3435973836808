const SecurityOfYourPersonalData = () => {
    return <div>
        <h2 className="text-[1.1rem] lg:text-[1.8rem] mb-5">Security of Your Personal Data</h2>
        <p className="my-5">
            The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure.
            While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
        </p>
    </div>;
}

export default SecurityOfYourPersonalData;