const PacBlob = ({ posTop, posLeft, dir, hide }) => {
    // 0 == right
    // 1 == bottom
    // 2 == left
    // 3 == top
    let classes = `w-[18%] h-[18%] absolute rounded-full bg-[#171717] border-white shadow-[inset_0px_0px_7.5px_5px_rgba(234,179,8,1)] md:shadow-[inset_0px_0px_15px_10px_rgba(234,179,8,1)] overflow-hidden transition-all duration-150 ease-linear`;

    const getTopPos = () => {
        switch (posTop) {
            case -1:
                return "top-[-19%]";
            case 0:
                return "top-[1%]";
            case 1:
                return "top-[21%]";
            case 2:
                return "top-[41%]";
            case 3:
                return "top-[61%]";
            case 4:
                return "top-[81%]";
            default:
                return "top-[121%]"
        }
    }

    const getLeftPos = () => {
        switch (posLeft) {
            case -1:
                return "left-[-19%]";
            case 0:
                return "left-[1%]";
            case 1:
                return "left-[21%]";
            case 2:
                return "left-[41%]";
            case 3:
                return "left-[61%]";
            case 4:
                return "left-[81%]";
            default:
                return "left-[121%]"
        }
    }

    if (dir === 1) {
        classes = `${ classes } rotate-[90deg]`;
    } else if (dir === 2) {
        classes = `${ classes } rotate-[180deg]`;
    } else if (dir === 3) {
        classes = `${ classes } rotate-[270deg]`;
    }

    classes = `${ classes } ${ getTopPos() }`;
    classes = `${ classes } ${ getLeftPos() }`;
    classes = `${ classes } ${ hide ? 'hidden' : '' }`;

    return <span className={classes}>
        <span className="w-[15%] h-[15%] absolute rounded-full top-[20%] right-[35%] bg-white"></span>
        <span className="w-[15%] h-[15%] absolute rounded-full bottom-[20%] right-[35%] bg-white"></span>
    </span>
}

export default PacBlob;