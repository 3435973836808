import TypesOfDataCollected from "./CollectingAndUsingYourPersonalData/TypesOfDataCollected";
import UseOfYourPersonalData from "./CollectingAndUsingYourPersonalData/UseOfYourPersonalData";
import RetentionOfYourPersonalData from "./CollectingAndUsingYourPersonalData/RetentionOfYourPersonalData";
import TransferOfYourPersonalData from "./CollectingAndUsingYourPersonalData/TransferOfYourPersonalData";
import DisclosureOfYourPersonalData from "./CollectingAndUsingYourPersonalData/DisclosureOfYourPersonalData";
import SecurityOfYourPersonalData from "./CollectingAndUsingYourPersonalData/SecurityOfYourPersonalData";

const CollectingAndUsingYourPersonalData = ({showContent}) => {
    const children = [
        {
            name: "Types of Data Collected",
            component: <TypesOfDataCollected />,
            parent: "Collecting and Using Your Personal Data"
        },
        {
            name: "Use of Your Personal Data",
            component: <UseOfYourPersonalData />,
            parent: "Collecting and Using Your Personal Data"
        },
        {
            name: "Retention of Your Personal Data",
            component: <RetentionOfYourPersonalData />,
            parent: "Collecting and Using Your Personal Data"
        },
        {
            name: "Transfer of Your Personal Data",
            component: <TransferOfYourPersonalData />,
            parent: "Collecting and Using Your Personal Data"
        },
        {
            name: "Disclosure of Your Personal Data",
            component: <DisclosureOfYourPersonalData />,
            parent: "Collecting and Using Your Personal Data"
        },
        {
            name: "Security of Your Personal Data",
            component: <SecurityOfYourPersonalData />,
            parent: "Collecting and Using Your Personal Data"
        }
    ];

    const content = children.map((child, childIndex) => {
        return <h2 className="text-[1.1rem] lg:text-[1.8rem] mb-5 flex" key={`parent-content-${child.name}`} onClick={() => {showContent(child.name)}}>
            <span className="material-icons-round flex text-[1.6rem] lg:text-[2.8rem]">article</span>
            <span className="pl-5 transition-all ease-linear duration-300 cursor-pointer hover:text-blue-300">{child.name}</span>
        </h2>
    })

    return <div>
        <h1 className="text-[1.2rem] lg:text-[2rem] mb-5">Collecting and Using Your Personal Data</h1>

        {content}
    </div>
}

export default CollectingAndUsingYourPersonalData;