import { FacebookShareButton, TwitterShareButton, RedditShareButton, FacebookMessengerShareButton, FacebookIcon, TwitterIcon, RedditIcon, FacebookMessengerIcon } from "react-share"
import "./css/style.css"

const GameEnd = ({ name, message, title, status, resetFunction, resetParam = [] }) => {
    return <div className={`w-[100%] h-[100%] flex flex-col ${ status === null ? "bg-[#171717]/[0] -z-50" : "bg-[#171717]/[0.8] z-50" } transition-all ease-linear duration-300 justify-center fixed top-0 left-0 bg-[#171717]/[0.8]`}>
        <div className="flex flex-row justify-center text-center">
            <div className="game-end-hero w-[90vw] md:w-[50vw] xl:w-[40vw] min-h-[40vh] px-10 py-5 rounded-3xl">
                <h1 className="text-[1.1rem] lg:text-[2rem] my-5">{title}</h1>
                <span className={`material-icons-round inline-block my-5 text-[4rem] md:text-[8rem] ${ status === 'win' ? '' : 'hidden' }`}>sentiment_very_satisfied</span>
                <span className={`material-icons-round inline-block my-5 text-[4rem] md:text-[8rem] ${ status === 'lose' ? '' : 'hidden' }`}>sentiment_dissatisfied</span>
                <span className={`material-icons-round inline-block my-5 text-[4rem] md:text-[8rem] ${ status === "neither" ? '' : 'hidden' }`}>sentiment_neutral</span>
                <h4 className="text-[0.9rem] md:text-[1rem] mt-5 text-center">Share your results with your friends!</h4>
                <div className="my-5 flex flex-row justify-center">
                    <FacebookShareButton url="https://timanity.org" quote={message} hashtag={`#Play2Help #TimanityOrg`}>
                        <FacebookIcon round size={40} className="mx-5" />
                    </FacebookShareButton>
                    <RedditShareButton url="https://timanity.org" title={message} windowWidth={660} windowHeight={460}>
                        <RedditIcon size={40} round className="mx-5" />
                    </RedditShareButton>
                    <TwitterShareButton url="https://timanity.org" title={message} hashtags={[`${name.split(" ").join("")}`, `TimanityOrg`, `Play2Help`]} related={["TimanityOrg"]}>
                        <TwitterIcon round size={40} className="mx-5" />
                    </TwitterShareButton>
                    <FacebookMessengerShareButton url={"https://timanity.org"} appId="515934086712478">
                        <FacebookMessengerIcon size={40} round className="mx-5" />
                    </FacebookMessengerShareButton>
                </div>
                <div className="mb-5">
                    <button className="bg-green-500 px-10 py-3 hover:px-16 hover:bg-green-500/[0.7] transition-all ease-linear duration-200 rounded-full text-[1.1rem]" onClick={resetFunction}>Play again</button>
                </div>
            </div>
        </div>
    </div>
}

export default GameEnd;