const AdsProgramContent = () => {
    return <div>
        <h1 className="text-[1.2rem] lg:text-[2rem] mb-5">Ads Program Policy</h1>
        <p className="my-5">
            The Initiative uses Google Adsense, as such, the following policies also apply:
        </p>
        <ul className="pl-5 py-5 list-disc">
            <li className="py-2">
                Third party vendors, including Google, use cookies to serve ads based on Your prior visits to Our website or other websites.
            </li>
            <li className="py-2">
                Google's use of advertising cookies enables it and its partners to serve ads to You based on Your visit to Our site and/or other sites on the Internet.
            </li>
            <li className="py-2">
                You may opt out of personalized advertising by visiting <a href="https://www.google.com/settings/ads" className="text-green-400 hover:text-blue-300 transition-all ease-linear duration-300" rel="noreferrer" target="_blank">Ads Settings</a>. <br />
                Alternatively, You can opt out of a third-party vendor's use of cookies for personalized advertising by visiting <a href="www.aboutads.info" className="text-green-400 hover:text-blue-300 transition-all ease-linear duration-300" rel="noreferrer" target="_blank">www.aboutads.info</a>
            </li>
        </ul>
    </div>;
}

export default AdsProgramContent;