import React from "react";
import Hero from "./components/Hero";
import WhatIsTimanity from "./components/WhatIsTimanity";
import HowDoesItWork from "./components/HowDoesItWork";
import WhyUseTimanity from "./components/WhyUseTimanity"
import Footer from "./components/Footer";
import TopScroll from "../Utilities/Components/TopScroll";

import "./css/style.css"

const Landing = () => {

    return <div className="min-h-screen justify-center pt-5 md:pt-12 xl:pt-32 bg-[#171717]" style={{ boxShadow:  "inset 20px 0 20px -20px rgba(59,130,246,1), inset -20px 0px 20px -20px rgba(59,130,246,1)" }}>
        <TopScroll />
        <Hero />
        <WhatIsTimanity />
        <HowDoesItWork />
        <WhyUseTimanity />
        <Footer />
    </div>
}

export default Landing;