import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';


const AppUrlListener = () => {
    const navigate = useNavigate();

    useEffect(() => {
        App.addListener('appUrlOpen', event => {
            const slug = event.url.split(".org").pop();

            if (slug) {
                navigate(slug);
            }
        })
    }, []);

    return null;
}

export default AppUrlListener;