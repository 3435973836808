import { Link } from "react-router-dom";

const Footer = () => {
    return <footer className="w[100%] min-h-[10vh] flex justify-around flex-col py-10 lg:py-20 landing-footer bg-blend-blended bg-[rgba(50,50,60,0.4)]">

        <div className="w-[80%] mx-auto flex justify-around lg:justify-start lg:flex-start flex-col lg:flex-row">
            <div className="flex flex-col mt-16 lg:my-auto order-2 lg:order-1 lg:mr-12">
                <div className="flex flex-row justify-center">
                    <img src="../img/timanity-borderless-white.svg" className="w-[2rem]" alt="Timanity Logo" />
                    <span className="tracking-[0.5rem] text-blue-400 font-semibold text-[2rem] ml-5">Timanity</span>
                </div>
                <div className="flex flex-row justify-around mt-3">
                    <a href="https://facebook.com/timanity.org" target="_blank" rel="noreferrer" className="my-auto text-[1.2rem] hover:text-blue-400 transition-all duration-200"><i className="icon-facebook1"></i></a>
                    <a href="https://twitter.com/TimanityOrg" target="_blank" rel="noreferrer" className="my-auto text-[1.2rem] hover:text-blue-400 transition-all duration-200"><i className="icon-twitter"></i></a>
                    <a href="https://instagram.com/TimanityOrg" target="_blank" rel="noreferrer" className="my-auto text-[1.2rem] hover:text-blue-400 transition-all duration-200"><i className="icon-instagram"></i></a>
                    {/* <a href="https://instagram.com" className="my-auto hover:text-blue-400 transition-all duration-200"><i class="icon-instagram text-[1.5rem]"></i></a> */}
                </div>
            </div>
            <div className="flex flex-col order-1 lg:order-2 lg:mx-12">
                <h3 className="text-[1.2rem] text-[#aaa] flex flex-row items-center border-b-2">
                    <span className="material-icons-round flex mr-2">info</span>
                    <span className="my-auto">Info Pages</span>
                </h3>
                <ul className="my-1.5">
                    <li className="my-1.5 hover:text-blue-400 transition-all duration-200 cursor-pointer"><Link to="/privacy-policy">Privacy Policy</Link></li>
                    <li className="my-1.5 hover:text-blue-400 transition-all duration-200 cursor-pointer"><Link to="/privacy-policy/Ads Program Policy">Disable personalized ads</Link></li>
                    {/* <li className="my-1.5 hover:text-blue-400 transition-all duration-200 cursor-pointer"><Link to="/credits-page">Credits Page</Link></li> */}
                    {/* <li className="my-1.5 hover:text-blue-400 transition-all duration-200 cursor-pointer"><Link to="/projects">Projects Page</Link></li> */}
                </ul>
            </div>
            <div className="mx-auto hidden lg:block order-3"></div>
            <div className="mt-16 text-center lg:my-auto order-last lg:mx-12">
                Development Partner
                <div className="cursor-pointer flex flex-row justify-center" onClick={() => {window.open("https://www.eveneer.xyz", "_blank")}}>
                    <img src="../img/eveneer-logo.svg" alt="Eveneer Logo" className="w-[2rem] mr-2" />
                    <h3 className="text-[2rem]">Eveneer</h3>
                </div>
            </div>
        </div>
        <div className="text-center pt-10">
            <span className="text-blue-400 text-semibold">Timanity</span> © {(new Date()).getFullYear()}
        </div>
    </footer>
}

export default Footer;