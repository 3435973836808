const TransferOfYourPersonalData = () => {
    return <div>
        <h2 className="text-[1.1rem] lg:text-[1.8rem] mb-5">Transfer of Your Personal Data</h2>
        <p className="my-5">
            Your information, including Personal Data, is processed at the Initiative's operating offices and in any other places where the parties involved in the processing are located.
            It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.
        </p>
        <p className="my-5">
            Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.
        </p>
        <p className="my-5">
            The Initiative will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.
        </p>
    </div>;
}

export default TransferOfYourPersonalData;