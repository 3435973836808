import { Link } from "react-router-dom";

const WhatIsTimanity = () => {
    return <div className="w[100%] min-h-[50vh] flex flex-col justify-center px-[5%] xl:px-[10%] py-10 xl:py-32 landing-about-hero bg-blend-blended bg-[rgba(50,50,60,0.4)]">

        <div>
            <div className="my-auto flex flex-col xl:flex-row w-[100%]">
                <div className="text-[1.2rem] lg:text-[2rem] xl:w-[30%] tracking-[0.25rem] xl:tracking-[1rem] text-center my-auto">
                    <h1 className="blur-none">What is <span className="font-semibold text-blue-400">Timanity</span>?</h1>
                </div>
                
                <div className="text-[0.9rem] xl:w-[70%] xl:text-[1.2rem] my-auto bg-[#171717]/[0.9] p-5 xl:p-10 rounded-2xl">
                    <p className="text-center">
                        Timanity is a fund raising platform that generates funds for socio-economic and humanitarian efforts when you play games on it
                    </p>
                    <blockquote className="text-blue-400 font-semibold italic text-center">#Play2Help</blockquote>
                </div>
            </div>
        </div>
        {/* <div className="flex flex-row justify-center mt-16">
            <Link to="/games" className="bg-blue-500 py-5 w-[95%] md:w-[60%] lg:w-[40%] text-center text-[1rem] md:text-[1.3rem] rounded-full transition-all duration-300 ease-linear hover:bg-blue-400 shadow-xl shadow-[#171717]/[0.7]">
                Play Now!!!
            </Link>
        </div> */}
    </div>;
}

export default WhatIsTimanity;