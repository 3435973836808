import React, { useEffect, useState } from "react";
import PacBlob from "./PacBlob";
import PacBlobHelper from "../helpers/PacBlobHelper";

const Hero = () => {
    const [pacBlob, setPacBlob] = useState(new PacBlobHelper());
    // const setInitTime = () => {
    //     let date = new Date();
    //     let [hrs, mins, secs] = [date.getHours(), date.getMinutes(), date.getSeconds()];

    //     return `${ hrs < 10 ? '0' + hrs : hrs }:${ mins < 10 ? '0' + mins : mins }:${ secs < 10 ? '0' + secs : secs }`;
    // }

    // const [currentTime, setCurrentTime] = useState(setInitTime());

    // useEffect(() => {
    //     const id = setInterval(() => {
    //         setCurrentTime(setInitTime())
    //     }, 1000);

    //     return () => clearInterval(id);
    // }, [currentTime])

    useEffect(() => {
        let clone = Object.assign(Object.create(Object.getPrototypeOf(pacBlob)), pacBlob);
        
        const id = setInterval(() => {
            setPacBlob(clone.nextPoint());
        }, 500);

        return () => clearInterval(id);
    }, [pacBlob])

    // return <div className="flex flex-col justify-evenly xl:justify-start">
    //         <div className="w-[100%] max-w-[600px] mx-auto px-5 relative">
    //             <div className="border-[3px] text-center w-[100%] before:block before:pt-[100%] drop-shadow-[0px_0px_30px_rgba(59,130,246,1)] ring-[7px] ring-inset shadow-2xl shadow-[rgba(59,130,246,1)]"></div>
    //             <div className="absolute top-0 w-[100%] h-[100%] flex flex-col justify-center left-0 text-center">
    //                 <img src="/img/timanity-borderless-white.svg" alt="Timeate Logo" className="w-[60%] mx-auto" />
    //                 <PacBlob posTop={pacBlob.getYCoord()} posLeft={pacBlob.getXCoord()} dir={pacBlob.getDir()} hide={pacBlob.hide} />
    //             </div>
    //         </div>
    //         <h1 className="text-[1.2rem] lg:text-[2rem] tracking-[0.5rem] xl:tracking-[1rem] text-center mt-12 xl:mt-20">Donate in <span className="font-semibold text-blue-500">Time</span></h1>
    //         <div className="border-[3px] text-center rounded-[50%] w-[80%] mx-auto mt-10 xl:mt-20 drop-shadow-[0px_0px_30px_rgba(59,130,246,1)] ring-[7px] ring-inset shadow-2xl shadow-[rgba(59,130,246,1)]"></div>
    //     </div>;

    return <div className="flex flex-col justify-evenly xl:justify-start">
            <div className="w-[80%] md:w-[90%] flex flex-col justify-center max-w-[600px] mx-auto overflow-hidden relative">
                <img src="/img/timanity-borderless-white.svg" alt="Timeate Logo" className="w-[100%] opacity-0" />
                <span className="shadow-[inset_0px_0px_12.5px_5px_rgba(59,130,246,1)] md:shadow-[inset_0px_0px_25px_10px_rgba(59,130,246,1)] border-2 rounded-b-2xl md:rounded-b-3xl top-0 left-[20%] w-[20%] h-[60%] absolute"></span>
                <span className="shadow-[inset_0px_0px_12.5px_5px_rgba(59,130,246,1)] md:shadow-[inset_0px_0px_25px_10px_rgba(59,130,246,1)] border-2 rounded-2xl md:rounded-3xl top-0 left-0 w-[60%] h-[20%] absolute"></span>
                <span className="shadow-[inset_0px_0px_12.5px_5px_rgba(59,130,246,1)] md:shadow-[inset_0px_0px_25px_10px_rgba(59,130,246,1)] border-2 rounded-2xl md:rounded-3xl top-0 left-[80%] w-[20%] h-[40%] absolute"></span>
                <span className="shadow-[inset_0px_0px_12.5px_5px_rgba(59,130,246,1)] md:shadow-[inset_0px_0px_25px_10px_rgba(59,130,246,1)] border-2 rounded-l-2xl rounded-b-2xl md:rounded-l-3xl md:rounded-b-3xl top-[60%] left-[60%] w-[20%] h-[40%] absolute"></span>
                <span className="shadow-[inset_0px_0px_12.5px_5px_rgba(59,130,246,1)] md:shadow-[inset_0px_0px_25px_10px_rgba(59,130,246,1)] border-2 rounded-t-2xl rounded-r-2xl md:rounded-t-3xl md:rounded-r-3xl top-[60%] left-[60%] w-[40%] h-[20%] absolute"></span>
                <span className="shadow-[inset_0px_0px_12.5px_5px_rgba(59,130,246,1)] md:shadow-[inset_0px_0px_25px_10px_rgba(59,130,246,1)] border-2 rounded-2xl md:rounded-3xl top-[80%] left-0 w-[40%] h-[20%] absolute"></span>
                <PacBlob posTop={pacBlob.getYCoord()} posLeft={pacBlob.getXCoord()} dir={pacBlob.getDir()} hide={pacBlob.hide} />

            </div>
            <h1 className="text-[1.2rem] lg:text-[2rem] tracking-[0.5rem] xl:tracking-[1rem] text-center mt-12 md:mt-20">Donate in <span className="font-semibold text-blue-500">Time</span></h1>
            <div className="border-[3px] text-center rounded-[50%] w-[80%] mx-auto mt-10 xl:mt-20 drop-shadow-[0px_0px_30px_rgba(59,130,246,1)] ring-[7px] ring-inset shadow-2xl shadow-[rgba(59,130,246,1)]"></div>
        </div>;
}

export default Hero;