import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./css/style.css";
import InterpretationAndDefinition from "./components/InterpretationAndDefinition";
import CollectingAndUsingYourPersonalData from "./components/CollectingAndUsingYourPersonalData";
import TypesOfDataCollected from "./components/CollectingAndUsingYourPersonalData/TypesOfDataCollected";
import UseOfYourPersonalData from "./components/CollectingAndUsingYourPersonalData/UseOfYourPersonalData";
import RetentionOfYourPersonalData from "./components/CollectingAndUsingYourPersonalData/RetentionOfYourPersonalData";
import TransferOfYourPersonalData from "./components/CollectingAndUsingYourPersonalData/TransferOfYourPersonalData";
import DisclosureOfYourPersonalData from "./components/CollectingAndUsingYourPersonalData/DisclosureOfYourPersonalData";
import SecurityOfYourPersonalData from "./components/CollectingAndUsingYourPersonalData/SecurityOfYourPersonalData";
import AdsProgramContent from "./components/AdsProgramContent";
import ChildrensPrivacy from "./components/ChildrensPrivacy";
import LinkToOtherWebsites from "./components/LinkToOtherWebsites";
import ChangesToThisPrivacyPolicy from "./components/ChangesToThisPrivacyPolicy";
import ContactUs from "./components/ContactUs";
import Footer from "../../Landing/components/Footer";

const PrivacyPolicy = () => {
    const navigate = useNavigate();

    const showContent = (name) => {
        navigate(`/privacy-policy/${ name }`);
        window.scrollTo(0,0);
    }

    const loadContent = (name) => {
        const privacyContentClone = Object.assign(Object.create(Object.getPrototypeOf(privacyContent)), privacyContent);
        privacyContentClone.active = name;
        setPrivacyContent(privacyContentClone);
    }

    const activeComponent = decodeURI(useLocation().pathname.replace("/privacy-policy", "")).split("/").join("");

    const [privacyContent, setPrivacyContent] = useState({
        active: "Interpretation and Definitions",
        sections: [
            {
                name: "Interpretation and Definitions",
                component: <InterpretationAndDefinition />,
                parent: null
            },
            {
                name: "Collecting and Using Your Personal Data",
                component: <CollectingAndUsingYourPersonalData showContent={showContent} />,
                parent: null
            },
            {
                name: "Types of Data Collected",
                component: <TypesOfDataCollected />,
                parent: "Collecting and Using Your Personal Data"
            },
            {
                name: "Use of Your Personal Data",
                component: <UseOfYourPersonalData />,
                parent: "Collecting and Using Your Personal Data"
            },
            {
                name: "Retention of Your Personal Data",
                component: <RetentionOfYourPersonalData />,
                parent: "Collecting and Using Your Personal Data"
            },
            {
                name: "Transfer of Your Personal Data",
                component: <TransferOfYourPersonalData />,
                parent: "Collecting and Using Your Personal Data"
            },
            {
                name: "Disclosure of Your Personal Data",
                component: <DisclosureOfYourPersonalData />,
                parent: "Collecting and Using Your Personal Data"
            },
            {
                name: "Security of Your Personal Data",
                component: <SecurityOfYourPersonalData />,
                parent: "Collecting and Using Your Personal Data"
            },
            {
                name: "Ads Program Policy",
                component: <AdsProgramContent />,
                parent: null
            },
            {
                name: "Children's Privacy",
                component: <ChildrensPrivacy />,
                parent: null
            },
            {
                name: "Links to Other Websites",
                component: <LinkToOtherWebsites />,
                parent: null
            },
            {
                name: "Changes to this Privacy Policy",
                component: <ChangesToThisPrivacyPolicy />,
                parent: null
            },
            {
                name: "Contact Us",
                component: <ContactUs />,
                parent: null
            },
        ],
    });

    useEffect(() => {
        const content = privacyContent.sections.find(c => c.name === activeComponent )
        if (content !== undefined && privacyContent.active !== activeComponent) {
            loadContent(activeComponent);
        }
    }, [privacyContent, activeComponent, loadContent]);

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    const sectionContent = privacyContent.sections.filter(s => s.parent === null).map((section, sectionIndex) => {
        if (privacyContent.sections.filter(s => s.parent === section.name).length === 0) {
            return <div className="flex text-[0.9rem] xl:text-[1.1rem] my-5" key={`${section.name.split(" ").join("_")}`} onClick={() => {showContent(section.name)}}>
                <span className="material-icons-round flex text-[1.3rem] xl:text-[1.8rem]">article</span>
                <span className={`inline-block ml-1 transition-all ease-linear duration-300 cursor-pointer ${ privacyContent.sections.find(s => s.name === privacyContent.active).name === section.name ? "text-green-300" : "hover:text-blue-300" }`}>{section.name}</span>
            </div>;
        } else {
            return <div className="flex flex-col text-[0.9rem] xl:text-[1.1rem]" key={`${section.name.split(" ").join("_")}`}>
                <div className="flex" onClick={() => {showContent(section.name)}}>
                    <span className="material-icons-round flex text-[1.3rem] xl:text-[1.8rem]">article</span>
                    <span className={`inline-block ml-1 transition-all ease-linear duration-300 cursor-pointer ${ privacyContent.sections.find(s => s.name === privacyContent.active).name === section.name ? "text-green-300" : "hover:text-blue-300" }`}>{section.name}</span>
                </div>
                <ul className="pl-12 list-disc">
                    {privacyContent.sections.filter(s => s.parent === section.name).map((subSection, subSectionIndex) => {
                        return <li className={`my-2 transition-all ease-linear duration-300 cursor-pointer ${ privacyContent.sections.find(s => s.name === privacyContent.active).name === subSection.name ? "text-green-300" : "hover:text-blue-300" }`} key={`${section.name.split(" ").join("_")}-${subSection.name.split(" ").join("_")}`} onClick={() => {showContent(subSection.name)}}>{subSection.name}</li>;
                    })}
                </ul>
            </div>
        }
    });

    return <div className="pt-5 lg:pt-20 min-h-[100vh] bg-[#171717]" style={{ boxShadow:  "inset 20px 0 20px -20px rgba(59,130,246,1), inset -20px 0px 20px -20px rgba(59,130,246,1)" }}>
        <div className="w-[90%] md:w-[80%] mx-auto text-[0.9rem] mb-10">
            <h1 className="text-[1.2rem] lg:text-[2rem] mb-5">Privacy Policy</h1>
            <div className="privacy-0-hero text-[0.9rem] rounded-2xl flex flex-col md:flex-row px-5 md:px-10 py-5 mb-10">
                <div className="md:w-[20%] text-center flex flex-col justify-center">
                    <span className="material-icons-round mx-auto text-[7rem] text-yellow-200 my-auto">warning</span>
                </div>
                <div>
                    <p className="text-right mb-3">Last Updated: April 3, 2022</p>
                    <p className="text-justify">
                        This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
                        We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
                    </p>
                </div>
            </div>
            <div className="flex flex-col lg:flex-row my-5">
                <div className="w-[100%] lg:w-[60%] lg:mr-[15%]">
                    {privacyContent.sections.find(s => s.name === privacyContent.active).component}
                </div>
                <div className="w-[100%] my-10 lg:w-[25%] bg-[#111718] rounded-2xl py-5 px-5 xl:px-10">
                    <div className="flex flex-col lg:sticky lg:top-20 text-[0.9rem]">
                        {sectionContent}
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
}

export default PrivacyPolicy;