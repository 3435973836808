import { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../Landing/components/Footer";
import "./css/style.css";
import QuestionsHelper from "./helpers/QuestionsHelper";
import TopScroll from "../Utilities/Components/TopScroll";

const AboutUs = () => {
    // const [questions, setQuestions] = useState(new QuestionsHelper());

    // const searchQuestion = (e) => {
    //     let filter = null;

    //     if (e.target.value.split(" ").join("").length >= 3) {
    //         filter = e.target.value;
    //     }

    //     const questionClone = Object.assign(Object.create(Object.getPrototypeOf(questions)), questions);
    //     setQuestions(questionClone.filterQuestions(filter));
    // }

    // const questionContent = questions.questions.map((q, qIndex) => {
    //     return <div className="my-10 md:my-32 flex flex-col md:flex-row p-10 rounded-3xl border-x-2 md:border-r-0  border-blue-400" key={`q-set-${q.id}`}>
    //         <div className="min-w-[100%] my-auto md:min-w-[30%] md:max-w-[30%]">
    //             <h3 className="text-[1.2rem] lg:text-[1.5rem]">{q.question}</h3>
    //         </div>
    //         <div className="border-b-2 md:border-r-2 mt-10 md:mt-0 min-w-[100%] md:min-w-[0%] border-blue-400 mx-auto">

    //         </div>
    //         <div className="my-auto md:w-[60%] text-[0.9rem] text-justify py-5">
    //             {q.answer}
    //         </div>
    //     </div>
    // })

    // return <div className="w-[100] bg-[#171717] pt-5 lg:pt-20">
    //     <TopScroll />
    //     <div className="min-h-[80vh]">
    //         <div className="w-[90%] md:w-[80%] mx-auto">
    //             <h1 className="text-[1.2rem] lg:text-[2rem] mb-5">About</h1>
    //             <div className="about-0-hero rounded-2xl flex flex-col md:flex-row px-5 md:px-10 py-5 mb-10">
    //                 <div className="md:w-[20%] text-center flex flex-col justify-center">
    //                     <span className="material-icons-round mx-auto text-[7rem]  my-auto">info</span>
    //                 </div>
    //                 <div className="w-[100%] my-2">
    //                     <p className="text-right mb-3">Last Updated: April 3, 2022</p>
    //                     <p className="text-justify">
    //                         This is where we answer all of your burning questions! We will keep on updating and adding questions and answers to this page as you ask us the questions that you have.
    //                     </p>
    //                 </div>
    //             </div>
    //             <div className="flex flex-col w-[100%] md:w-[60%] mx-auto">
    //                 <input className="w-[100%] my-auto bg-white/[0.1] px-4 py-1 rounded-3xl border-2 border-blue-400/[0.5] focus:border-blue-400/[1] transition-all ease-linear duration-200" onKeyUp={searchQuestion} />
    //                 <h3 className="w-[100%] pr-5 my-auto text-right">Search your question</h3>
    //             </div>
    //             {questionContent}
    //             <div className={`mt-10 mb-16 ${ questions.questions.length === 0 ? '' : 'hidden' }`}>
    //                 <div className="text-center flex flex-col justify-center">
    //                     <div className="px-5">
    //                         <span className="material-icons-round text-[15rem] md:text-[20rem]">sentiment_very_dissatisfied</span><br />
    //                         <h1 className="text-[1.5rem] md:text-[2rem]">Could not find any answer</h1>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="about-1-hero rounded-2xl flex flex-col md:flex-row px-5 md:px-10 py-5 my-10">
    //                 <div className="md:w-[20%] text-center flex flex-col justify-center">
    //                     <span className="material-icons-round mx-auto text-[7rem]  my-auto">help</span>
    //                 </div>
    //                 <div className="w-[100%] my-auto">
    //                     <p className="text-justify my-auto">
    //                         Not finding the answer to your question? Send us a message on <Link to="/contact" className="text-green-300 hover:text-blue-300 transition-all ease-liner duration-200">@Contact</Link> and we'll update the question list ASAP.
    //                     </p>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    //     <Footer />
    // </div>;

    const [questions, setQuestions] = useState(new QuestionsHelper());

    const searchQuestion = (e) => {
        let filter = null;

        if (e.target.value.split(" ").join("").length >= 3) {
            filter = e.target.value;
        }

        const questionClone = Object.assign(Object.create(Object.getPrototypeOf(questions)), questions);
        setQuestions(questionClone.filterQuestions(filter));
    }

    const questionContent = questions.questions.map((q, qIndex) => {
        return <div className="my-10 md:my-16 flex flex-col md:flex-row p-10 rounded-3xl border-x-2 md:border-r-0 shadow-[inset_0px_0px_10px_5px_rgba(59,130,246,1)] drop-shadow-[0px_0px_10px_rgba(59,130,246,1)] border-blue-400" key={`q-set-${q.id}`}>
            <div className="min-w-[100%] my-auto md:min-w-[30%] drop-shadow-[0px_0px_10px_rgba(59,130,246,1)] md:max-w-[30%]">
                <h3 className="text-[1.2rem] lg:text-[1.5rem]">{q.question}</h3>
            </div>
            <div className="border-b-2 md:border-r-2 mt-10 md:mt-0 min-w-[100%] md:min-w-[0%] border-blue-400 mx-auto">

            </div>
            <div className="my-auto md:w-[60%] text-[0.9rem] drop-shadow-[0px_0px_10px_rgba(59,130,246,1)] text-justify py-5">
                {q.answer}
            </div>
        </div>
    })

    return <div className="w-[100] pt-5 lg:pt-20" style={{ boxShadow:  "inset 20px 0 20px -20px rgba(59,130,246,1), inset -20px 0px 20px -20px rgba(59,130,246,1)" }}>
        <TopScroll />
        <div className="min-h-[80vh]">
            <div className="w-[90%] md:w-[80%] mx-auto">
                {/* <h1 className="text-[1.2rem] lg:text-[2rem] mb-5">Questions</h1> */}
                <div className="about-0-hero rounded-2xl flex flex-col md:flex-row px-5 md:px-10 py-5 mb-10 shadow-[inset_0px_0px_10px_5px_rgba(59,130,246,1)] drop-shadow-[0px_0px_10px_rgba(59,130,246,1)]">
                    <div className="w-[40%] mx-auto md:mr-5 md:w-[15%] text-center flex flex-col justify-center drop-shadow-[0px_0px_10px_rgba(59,130,246,1)]">
                        {/* <img src={InfoSvg} className="w-[100%] mx-auto my-auto mb-10 md:mb-auto drop-shadow-[0px_0px_10px_rgba(59,130,246,1)]" alt="info" /> */}
                        <span className="material-icons-round mx-auto text-[7rem] drop-shadow-[0px_0px_10px_rgba(59,130,246,1)] my-auto">info</span>
                    </div>
                    <div className="w-[100%] my-2">
                        <p className="text-right mb-3">Last Updated: April 3, 2022</p>
                        <p className="text-justify">
                            This is where we answer all of your burning questions! We will keep on updating and adding questions and answers to this page as you ask us the questions that you have.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col w-[100%] md:w-[60%] mx-auto">
                    <input className="w-[100%] my-auto bg-white/[0.1] px-4 py-1 rounded-3xl border-2 border-blue-400/[0.5] focus:border-blue-400/[1] transition-all transform-gpu ease-linear duration-200" onKeyUp={searchQuestion} />
                    <h3 className="w-[100%] pr-5 my-auto text-right">Search your question</h3>
                </div>
                {questionContent}
                <div className={`mt-10 mb-16 ${ questions.questions.length === 0 ? '' : 'hidden' }`}>
                    <div className="text-center flex flex-col">
                        <div className="px-5">
                            <span className="text-[15rem] md:text-[20rem]">
                                {/* <img src={SentimentVeryDissatisfied} className="w-[100%]" alt="very sad" /> */}
                                <span className="material-icons-round text-[15rem] md:text-[20rem]">sentiment_very_dissatisfied</span><br />
                            </span>
                            <h1 className="text-[1.5rem] md:text-[2rem]">Could not find any answer</h1>
                        </div>
                    </div>
                </div>
                <div className="about-1-hero rounded-2xl flex flex-col md:flex-row px-5 md:px-10 py-5 my-10 shadow-[inset_0px_0px_10px_5px_rgba(59,130,246,1)] drop-shadow-[0px_0px_10px_rgba(59,130,246,1)]">
                    <div className="w-[40%] mx-auto md:mr-5 md:w-[15%] text-center flex flex-col justify-center drop-shadow-[0px_0px_10px_rgba(59,130,246,1)]">
                        {/* <img src={HelpSvg} className="w-[100%] mx-auto my-auto mb-10 md:mb-auto drop-shadow-[0px_0px_10px_rgba(59,130,246,1)]" alt="info" /> */}
                        <span className="material-icons-round mx-auto text-[7rem] drop-shadow-[0px_0px_10px_rgba(59,130,246,1)] my-auto">help</span>
                    </div>
                    <div className="w-[100%] my-auto">
                        <p className="text-justify my-auto">
                            Not finding the answer to your question? Send us a message on <Link to="/contact" className="text-green-300 hover:text-blue-300 transition-all transform-gpu ease-liner duration-200">@Contact</Link> and we'll update the question list ASAP.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>;
}

export default AboutUs;