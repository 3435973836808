import TopScroll from "../Utilities/Components/TopScroll";
import Footer from "../Landing/components/Footer";

const Contact = () => {
    return <div className="w-[100] flex flex-col justify-center min-h-[100vh] pt-5 contact-page-shadow contact-body">
        <TopScroll />
        <div className="flex flex-col justify-center text-center min-h-[80vh] px-5 drop-shadow-[0px_0px_30px_rgba(59,130,246,1)]">
            <p className="shadow-[inset_0px_0px_10px_5px_rgba(59,130,246,1)] mx-auto w-[90vw] md:w-[50vw]  border-[1px] rounded-[50px] py-28 px-5 bg-[#171717]/[0.5]">
                We can't wait to hear from you!!
                <br /><br /><br />
                Please contact us by filling this <a href="https://docs.google.com/forms/d/e/1FAIpQLSfKhc7d8YpcpwXI8mTgc-gwa7l1KSq_zPQuRrRFkmTrs3eWCg/viewform?usp=sf_link" target="_blank" rel="noreferrer" className="text-green-400 hover:text-blue-300 mx-1 transition-all transform-gpu ease-linear duration-300 border-b-2 border-[rgba(59,130,246,1)] font-bold">@form</a>
                or by emailing us at <span className="text-green-400 border-b-2 border-[rgba(59,130,246,1)] font-bold">hello@timanity.org</span>!
                <br /><br /><br />
                We promise to get back to you <span className="text-green-400 font-bold">ASAP</span>!
            </p>
        </div>
        <div className="bg-[#171717]">
            <Footer />
        </div>
    </div>
}

export default Contact;