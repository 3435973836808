import React from "react";
import GameThumb from "./GameThumb/GameThumb";
import games from "./Games";
import Footer from "../Landing/components/Footer";
import TopScroll from "../Utilities/Components/TopScroll";

class GameLanding extends React.Component {
    render() {
        return (
            <div className="w-[100%]">
                <TopScroll />
                <div className="container mx-auto min-h-[80vh] flex flex-col py-10 justify-around">
                    <div className="GameLanding flex p-3 flex-wrap my-auto justify-around">
                        {games.map((game, gameIndex) => {
                            return <GameThumb type="1" key={`gl-${gameIndex}`} name={game.name} route={game.route} logo={game.logo} addClasses={game.addClasses} />;
                        })}
                    </div>
                </div>
                <div className="bg-[#171717]">
                    <Footer />
                </div>
            </div>
        );
    }
}

export default GameLanding;