import { Link } from "react-router-dom";

const ContactUs = () => {
    return <div>
        <h1 className="text-[1.2rem] lg:text-[2rem] mb-5">Contact Us</h1>
        <p className="my-5">
            If you have any questions about this Privacy Policy, You can contact us:
        </p>
        <ul className="pl-5 py-5 list-disc">
            <li className="py-2">
                By visiting this page on our website: <Link to="https://timanity.org/contact" className="text-green-400 hover:text-blue-300 transition-all ease-linear duration-300">https://timanity.org/contact</Link>
            </li>
        </ul>
    </div>;
}

export default ContactUs;