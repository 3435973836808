import React, { useCallback, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import navigationData from './NavOptions'

const Nav = ({ currentRoute, setCurrentRoute }) => {
    const location = useLocation();

    return (
        <nav className="hidden lg:flex flex-row items-center h-[4rem] bg-[#171717]/[0.7] fixed w-[100%] z-40 px-[5rem] drop-shadow-[0_10px_20px_rgba(200,200,200,0.25)]">
            <Link to="/" className="text-gray-800 my-auto px-16 border-r border-blue-500 mr-16">
                <img src="/img/timanity-borderless-white.svg" className="w-[2.4rem] mx-auto" alt='Timanity Logo' />
            </Link>
            <ul className="flex flex-row my-auto">
                {navigationData.filter(n => n.name !== "Home").map((item, index) => {
                    return <Link to={item.route} onClick={() => setCurrentRoute(item.name)} key={`route-${index}-link`} className="flex flex-col mr-12">
                        <li
                            className={`text-white hover:text-blue-500 transition-all duration-300 ease-linear self-center cursor-pointer font-medium tracking-wide text-sm flex items-start justify-center my-auto text-[0.9rem] ${ location.pathname === item.route ? 'border-[2px] px-2 py-1 rounded-full border-blue-400 text-blue-400' : '' }`}
                            key={`route-${index}`}
                        >
                            {item.icon}
                            <span className="my-auto ml-2 font-[Nunito]">{item.name}</span>
                        </li>
                    </Link>
                })}
            </ul>
            {/* <button className={styles.actions}>Logout</button> */}
        </nav>
    );
}

const Tabbar = ({ currentRoute, setCurrentRoute }) => {
    const location = useLocation();

    useEffect(() => {
        const containOverscroll = ["/games/2048"];

        if (containOverscroll.includes(location.pathname)) {
            document.body.style.overscrollBehaviorY = "contain";
        } else {
            document.body.style.overscrollBehaviorY = "initial";
        }
    }, [location]);

    const getTabIcon = useCallback((item) => {
        switch (item.name) {
            case "About":
                return <span className="material-icons-round flex my-auto">info</span>;
            // case "Games":
            //     return <span className="material-icons-round flex">sports_esports</span>;
            case "Home":
                return <img src="/img/timanity-borderless-white.svg" className="w-[1.3rem] mx-auto" alt='Timanity Logo' />;
            case "Contact":
                return <span className="material-icons-round flex">alternate_email</span>;
            // case "Projects":
            //     return <span className="material-icons-round flex">checklist</span>;
            // case "Login":
            //     return <span className="material-icons-round flex">power_settings_new</span>;
            default:
                return;
        }
    }, []);

    return (
        <nav className="flex lg:hidden flex-row items-center justify-around px-8 h-[3rem] bg-blue-500/[0.9] visible lg:invisible fixed bottom-0 w-full rounded-t-3xl text-2xl">
            {navigationData.map((item, index) => (
                <Link to={item.route} onClick={() => setCurrentRoute(item.name)} key={`route-${index}-link`}>
                    <span
                        key={index}
                        className={`text-white hover:text-[#444] transition-all duration-300 ease-linear cursor-pointer w-[4rem] h-full flex items-center justify-center ${ location.pathname === item.route ? 'tabItemActive' : '' }`}
                    >
                        <span className="-mb-1">{getTabIcon(item)}</span>
                    </span>
                </Link>
            ))}
        </nav>
    );
};

export {Nav, Tabbar};