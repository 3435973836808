const InterpretationAndDefinition = () => {
    return <div>
        <h1 className="text-[1.2rem] lg:text-[2rem] mb-5">Interpretation and Definitions</h1>
        <h2 className="text-[1.1rem] lg:text-[1.8rem] mb-5">Interpretation</h2>
        <p className="mb-5">
            The words of which the initial letter is capitalized have meanings defined under the following conditions.
            The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
        </p>
        <h2 className="text-[1.1rem] lg:text-[1.8rem] mb-5">Definitions</h2>
        <p>
            For the purposes of this Privacy Policy, the following definitions apply:
        </p>
        <div className="pl-5">
            <ul className="list-disc">
                <li className="py-2">
                    <span className="text-blue-400 font-semibold">Account</span> means a unique account created for You to access our Service or parts of our Service.
                </li>
                <li className="py-2">
                    <span className="text-blue-400 font-semibold">Initiative</span> (referred to as either "<span className="text-blue-400 font-semibold">the Initiative</span>", "<span  className="text-blue-400 font-semibold">We</span>", "<span  className="text-blue-400 font-semibold">Us</span>" or "<span  className="text-blue-400 font-semibold">Our</span>" in this Agreement) refers to Timanity.
                </li>
                <li className="py-2">
                    <span className="text-blue-400 font-semibold">Cookies</span> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.
                </li>
                <li className="py-2">
                    <span className="text-blue-400 font-semibold">Device</span> means any device that can access the Service such as a computer, a cell phone or a digital tablet.
                </li>
                <li className="py-2">
                    <span className="text-blue-400 font-semibold">Personal Data</span> is any information that relates to an identified or identifiable individual.
                </li>
                <li className="py-2">
                    <span className="text-blue-400 font-semibold">Service</span> refers to the Website.
                </li>
                <li className="py-2">
                    <span className="text-blue-400 font-semibold">Service Provider</span> means any natural or legal person who processes the data on behalf of the Initiative. It refers to third-party companies or individuals employed by the Initiative to facilitate the Service, to provide the Service on behalf of the Initiative, to perform services related to the Service or to assist the Initiative in analyzing how the Service is used.
                </li>
                <li className="py-2">
                    <span className="text-blue-400 font-semibold">Third-party Social Media Service</span> refers to any website or any social network website through which a User can log in or create an account to use the Service.
                </li>
                <li className="py-2">
                    <span className="text-blue-400 font-semibold">Usage Data</span> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
                </li>
                <li className="py-2">
                    <span className="text-blue-400 font-semibold">Website</span> refers to Timanity, accessible from <a href="https://timanity.org" className="text-green-400 hover:text-blue-300 transition-all ease-linear duration-300" rel="noreferrer">https://timanity.org</a>
                </li>
                <li className="py-2">
                    <span className="text-blue-400 font-semibold">You</span> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                </li>
            </ul>
        </div>
    </div>
}

export default InterpretationAndDefinition;