const DisclosureOfYourPersonalData = () => {
    return <div>
        <h2 className="text-[1.1rem] lg:text-[1.8rem] mb-5">Disclosure of Your Personal Data</h2>

        <h2 className="text-[1rem] lg:text-[1.6rem] mb-5">Business Transactions</h2>
        <p className="my-5">
            If the Initiative is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred.
            We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
        </p>

        <h2 className="text-[1rem] lg:text-[1.6rem] mb-5">Law enforcement</h2>
        <p className="my-5">
            Under certain circumstances, the Initiative may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
        </p>

        <h2 className="text-[1rem] lg:text-[1.6rem] mb-5">Other legal requirements</h2>
        <p className="my-5">
            The Initiative may disclose Your Personal Data in the good faith belief that such action is necessary to:
        </p>
        <ul className="pl-5 py-5 list-disc">
            <li className="py-2">
                Comply with a legal obligation
            </li>
            <li className="py-2">
                Protect and defend the rights or property of the Initiative
            </li>
            <li className="py-2">
                Prevent or investigate possible wrongdoing in connection with the Service
            </li>
            <li className="py-2">
                Protect the personal safety of Users of the Service or the public
            </li>
            <li className="py-2">
                Protect against legal liability
            </li>
        </ul>
    </div>;
}

export default DisclosureOfYourPersonalData;