import Footer from "../../Landing/components/Footer";

const NotFound404 = () => {
    return <div className="w-100 bg-[#171717]">
        <div className="min-h-[100vh] text-center flex flex-col justify-center">
            <div className="px-5">
                <span className="material-icons-round text-[15rem] md:text-[20rem]">sentiment_very_dissatisfied</span><br />
                <h1 className="text-[2.5rem] md:text-[5rem]">404 Not Found</h1>
            </div>
        </div>
        <Footer />
    </div>
}

export default NotFound404;