const navOptions = [
    {
        name: "About",
        route: "/about",
        icon: <span className="material-icons-round flex my-auto">info</span>
    },
    // {
    //     name: "Games",
    //     route: "/games",
    //     icon: <span className="material-icons-round flex my-auto">sports_esports</span>
    // },
    {
        name: "Home",
        route: "/",
        icon: <img src="/img/timanity-borderless-white.svg" className="w-[1.3rem] mx-auto" alt='Timanity Logo' />
    },
    {
        name: "Contact",
        route: "/contact",
        icon: <span className="material-icons-round flex my-auto">alternate_email</span>
    },
    // {
    //     name: "Projects",
    //     route: "/projects",
    //     icon: <span className="material-icons-round flex my-auto">checklist</span>
    // },
    // {
    //     name: "Login",
    //     route: "/login",
    //     icon: <span className="material-icons-round flex my-auto">power_settings_new</span>
    // }
];

export default navOptions;