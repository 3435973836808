import questions from "./questions";

class QuestionsHelper {
    constructor (filter) {
        this.questions = questions;
    }

    filterQuestions (filter = null) {
        this.questions = questions;
        
        if (filter !== null) {
            filter = filter.toLocaleLowerCase();
            filter = filter.split(" ").filter(p => p !== "");
            let temp = [];

            for (let i in filter) {
                for (let q in this.questions) {
                    let question = this.questions[q].question.toLocaleLowerCase();
                    let content =  this.questions[q].content.toLocaleLowerCase();

                    if (question.toLocaleLowerCase().includes(filter[i]) || content.toLocaleLowerCase().includes(filter[i])) {
                        if (temp.find(x => x.id === this.questions[q].id) === undefined) {
                            temp.push(this.questions[q])
                        }
                    }
                }
            }

            this.questions = temp;    
        }

        return this; 
    }
}

export default QuestionsHelper;