import { Link } from "react-router-dom";

const questions = [
    {
        id: 1,
        question: "Is Timanity a registered NGO?",
        answer: <p>
            No, Timanity is not a registered NGO, nor is it a product of any registered NGO.
            Timanity is an initiative and is currently being assessed for understanding its sustainability and feasibility.
            Should the results of the assessment be favorable, we hope to create an NGO surrounding the Timanity initiative.
        </p>,
        content: `No, Timanity is not a registered NGO, nor is it a product of any registered NGO. Timanity is an initiative and is currently being assessed for understanding its sustainability and feasibility. Should the results of the assessment be favorable, we hope to create an NGO surrounding the Timanity initiative.`,
    },
    // {
    //     id: 2,
    //     question: "How are the projects selected?",
    //     answer: <p>
    //         At the moment, we do not have any concrete process for the selection of projects since Timanity is still just an initiative and it is unsure if it is sustainable and feasible.
    //         However, should the results of the assessment be favorable, our first work would be to set up processes for project selection and we would like it to be in a manner so that everyone donating their time can have a voice in some manner.
    //         <br />
    //         <br />
    //         The initial projects have been selected according to the things that the founder wanted to work on for a very long time.
    //     </p>,
    //     content: `At the moment, we do not have any concrete process for the selection of projects since Timanity is still just an initiative and it is unsure if it is sustainable and feasible. However, should the results of the assessment be favorable, our first work would be to set up processes for project selection and we would like it to be in a manner so that everyone donating their time can have a voice in some manner.

    //     The initial projects have been selected according to the things that the founder wanted to work on for a very long time.`,
    // },
    // {
    //     id: 3,
    //     question: "How can I trust the credibility of this initiative?",
    //     answer: <p>
    //         While we cannot give any evidence to prove our credibility, let us ask you this.
    //         <span className="text-blue-400 font-semibold px-1">What do you have to lose?</span>
    //         All you have to do is play some games!
    //         The games have intentionally been kept as short games so that you can enjoy them when you have some free time, or are bored or have an awkward amount of time on your hands.
    //         <br />
    //         <br />
    //         Everything being said, we hope to prove our credibility by showing some results.
    //         We will be working on the <Link to="/projects" className="text-green-300 hover:text-blue-300 transition-all transform-gpu ease-liner duration-200">@Support humanitarian efforts</Link> project as soon as we receive the ads revenue from the ads program.
    //     </p>,
    //     content: `While we cannot give any evidence to prove our credibility, let us ask you this. What do you have to lose? All you have to do is play some games! The games have intentionally been kept as short games so that you can enjoy them when you have some free time, or are bored or have an awkward amount of time on your hands.
    //     Everything being said, we hope to prove our credibility by showing some results. We will be working on the @Support humanitarian efforts project as soon as we receive the ads revenue from the ads program.
    //     `,
    // },
    {
        id: 4,
        question: "I am already donating my time to Timanity, isn't that enough?",
        answer: <p>
            Our answer would be no!
            If you possess the means and are able to afford donating money or contributing by volunteering, you should do it!
            <br />
            <br />
            The Timanity platform was created to provider an alternative means for people who are unable to do so.
            But it still does not hurt to do more and playing a game or two on Timanity :p
        </p>,
        content: `Our answer would be no! If you possess the means and are able to afford donating money or contributing by volunteering, you should do it!

        The Timanity platform was created to provider an alternative means for people who are unable to do so.. But it still does not hurt to do more and playing a game or two on Timanity :p`,
    },
    {
        id: 5,
        question: "Why was Timanity created?",
        answer: <p>
            The Timanity concept was thought of by the founders, who like billions of other people, wanted to make positive changes in the world and was tired of waiting for these changes to happen.
            At the same time, due to the lack of the proper means, the founders felt that the founders were doing next to nothing to make a significant impact.
            At that moment, the founders thought “<span className="text-blue-400 font-semibold">Wouldn't it be cool if all the people in the world pitched in and concentrated their time? The mark we can leave with that would definitely be humongous!</span>”.
            That sounded like a game changer to us, so we started working on the initiative.
        </p>,
        content: `The Timanity concept was thought of by the founder, who like billions of other people, wanted to make positive changes in the world and was tired of waiting for these changes to happen. At the same time, due to the lack of the proper means, the founder felt that the founder was doing next to nothing to make a significant impact. At that moment, the founder thought “Hey! Wouldn't it be cool if all the people in the world pitched in and concentrated their time? The mark we can leave with that would definitely be humongous!”. With that thought, we started working on the initiative.`,
    },
    {
        id: 6,
        question: "Who is funding Timanity?",
        answer: <p>
            Unfortunately, the Timanity initiative does not have any funding source and the platform itself was developed on debt.
        </p>,
        content: `Unfortunately, the Timanity initiative does not have any funding source and the platform itself was developed on debt.`,
    },
    {
        id: 7,
        question: "How transparent is Timanity?",
        answer: <p>
            Since the concept of Timanity is leveraging globalization and the power of the people, we want to, and would try our best to make it as transparent as possible.
            Through every step of the way, we would love to disperse any and all information as long as it does not breach anyone's right to privacy and is within legal bounds.
        </p>,
        content: `Since the concept of Timanity is leveraging globalization and the power of the people, we want to, and would try our best to make it as transparent as possible. Through every step of the way, we would love to disperse any and all information as long as it does not breach anyone's right to privacy and is within legal bounds.`,
    },
    // {
    //     id: 8,
    //     question: "Would the founder's identity ever be revealed?",
    //     answer: <p>
    //         All in due time my friend, all in due time.
    //     </p>,
    //     content: `All in due time my friend, all in due time.`,
    // },
    // {
    //     id: 9,
    //     question: "What ads program is being used?",
    //     answer: <p>
    //         At the moment, Timanity has not subscribed to any ads program and is concentrating on increasing the number of users so that Timanity can qualify for an ads program.
    //     </p>,
    //     content: `At the moment, Timanity has not subscribed to any ads program and is concentrating on increasing the number of users so that Timanity can qualify for an ads program.`,
    // },
    {
        id: 10,
        question: "How is revenue generated on Timanity?",
        answer: <p>
            Timanity shows you ads when you play the games on the platform. Based on the policy of the ads program, this generates revenue which is then provided to us.
        </p>,
        content: `Timanity shows you ads when you play the games on the platform. Based on the policy of the ads program, this generates revenue which is then provided to us.`,
    },
    {
        id: 11,
        question: "Is Timanity breaching my privacy?",
        answer: <p>
            Absolutely not! We believe that everyone's right to privacy is universal and regardless of anything it cannot be breached or invaded.
            According to our <Link to="/privacy-policy" className="text-green-300 hover:text-blue-300 transition-all transform-gpu ease-liner duration-200">@Privacy Policy</Link>, we ONLY collect data when you have given permission to us to collect that data.
            If you feel that your privacy is being breached, please <Link to="/contact" className="text-green-300 hover:text-blue-300 transition-all transform-gpu ease-liner duration-200">@Contact</Link> us to let us know and we'll fix it ASAP.
        </p>,
        content: `Absolutely not! We believe that everyone's right to privacy is universal and regardless of anything it cannot be breached or invaded. According to our @Privacy Policy, we ONLY collect data when you have given permission to us to collect that data. If you feel that your privacy is being breached, please @Contact us to let us know and we'll fix it ASAP.`,
    },
    // {
    //     id: 1,
    //     question: "",
    //     answer: <p>

    //     </p>,
    // },
];

export default questions;