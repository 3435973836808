import { Link } from 'react-router-dom';

const HowDoesItWork = () => {
    return <div className="w[100%] min-h-[50vh] flex px-[5%] xl:px-[10%] py-10 xl:py-32 landing-how-does-it-work-hero bg-blend-blended bg-[rgba(50,50,60,0.4)]">

        <div className="my-auto flex flex-col xl:flex-row w-[100%]">
            <div className="text-[1.5rem] lg:text-[2rem] xl:w-[30%] tracking-[0.25rem] xl:tracking-[1rem] text-center my-auto">
                <h1 className="blur-none">How does it <span className="font-semibold text-blue-400">work</span>?</h1>
            </div>
            
            <div className="text-[0.9rem] xl:w-[70%] xl:text-[1.2rem] my-auto">
                <p className="text-center my-5 xl:mb-0">
                    It's as easy as <span className="text-blue-400 font-semibold">1, 2, 3</span>!!!
                </p>
                <div className="flex flex-nowrap no-scrollbar py-10 w-[100%] overflow-x-auto shadow-inset-how-does-it-work-steps">
                    <div className="min-w-[250px] xl:min-w-[300px] text-center min-h-[10vh] mx-5 py-5 bg-[#707070]/[0.5] rounded-3xl shadow-xl shadow-[#171717]/[0.7] px-5">
                        <h3 className="mb-5 font-semibold text-[1.2rem]">Step <span className="text-blue-300 font-semibold">&nbsp;1</span></h3>
                        Play any <Link to="/" className="text-green-300 hover:text-blue-300 transition-all transform-gpu ease-liner duration-200 font-semibold">games</Link> you like, as much as you like
                        {/* We'll show you ads on the game pages which are provided by the ads program. */}
                    </div>
                    <div className="min-w-[250px] xl:min-w-[300px] text-center min-h-[10vh] mx-5 py-5 bg-[#707070]/[0.5] rounded-3xl shadow-xl shadow-[#171717]/[0.7] px-5">
                        <h3 className="mb-5 font-semibold text-[1.2rem]">Step <span className="text-blue-300 font-semibold">&nbsp;2</span></h3>
                        We'll show you ads through the ads program
                        {/* Based on the policy of the ads program, revenue will be generated which will be provided to us on the following month by the ads program. */}
                    </div>
                    <div className="min-w-[250px] xl:min-w-[300px] text-center min-h-[10vh] mx-5 py-5 bg-[#707070]/[0.5] rounded-3xl shadow-xl shadow-[#171717]/[0.7] px-5">
                        <h3 className="mb-5 font-semibold text-[1.2rem]">Step <span className="text-blue-300 font-semibold">&nbsp;3</span></h3>
                        The ads program pays us for showing ads!
                        {/* With the revenue that we receive we work on <Link to="/projects" className="text-green-300 hover:text-blue-300 transition-all transform-gpu ease-liner duration-200">Projects</Link>. We fund and work on the projects with the highest priorty first and then move to the next. */}
                    </div>
                </div>
            </div>
        </div>

    </div>;
}

export default HowDoesItWork;