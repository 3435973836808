import { Link } from 'react-router-dom';

const WhyUseTimanity = () => {
    return <div className="w[100%] min-h-[50vh] flex px-[5%] xl:px-[10%] py-10 xl:py-32 landing-why-use-timanity-hero bg-blend-blended bg-[rgba(50,50,60,0.4)]">

        <div className="my-auto flex flex-col xl:flex-row w-[100%]">
            <div className="text-[1.5rem] lg:text-[2rem] xl:w-[30%] tracking-[0.25rem] xl:tracking-[1rem] text-center my-auto">
                <h1 className="blur-none">Why use <span className="font-semibold text-blue-400">Timanity</span>?</h1>
            </div>
            
            <div className="text-[0.9rem] xl:w-[70%] xl:text-[1.2rem] my-auto">
                <div className="flex flex-nowrap no-scrollbar py-10 w-[100%] overflow-x-auto shadow-inset-how-does-it-work-steps">
                    <div className="min-w-[250px] xl:min-w-[300px] text-center min-h-[10vh] py-5 bg-[#707070]/[0.5] rounded-3xl shadow-xl shadow-[#171717]/[0.7] px-5 mx-5">
                        <h3 className="mb-5 font-semibold text-[1.2rem]">Anywhere <span className="text-blue-300 font-semibold">Anytime</span></h3>
                        Travelling 🚙? Waiting ⏳? Bored 🥱? On a break ⏱️? <br /><br />
                        <Link to="/" className="text-green-300 hover:text-blue-300 transition-all transform-gpu ease-liner duration-200 font-semibold">Game</Link> time!!!
                    </div>
                    
                    <div className="min-w-[250px] xl:min-w-[300px] text-center min-h-[10vh] py-5 bg-[#707070]/[0.5] rounded-3xl shadow-xl shadow-[#171717]/[0.7] px-5 mx-5">
                        <h3 className="mb-5 font-semibold text-[1.2rem]">Feel <span className="text-blue-300 font-semibold">Good</span></h3>
                        Like <Link to="/" className="text-green-300 hover:text-blue-300 transition-all transform-gpu ease-liner duration-200 font-semibold">games</Link>?<br /><br />
                        Do we need to say more?<br />😛 😛 😛
                    </div>

                    <div className="min-w-[250px] xl:min-w-[300px] text-center min-h-[10vh] py-5 bg-[#707070]/[0.5] rounded-3xl shadow-xl shadow-[#171717]/[0.7] px-5 mx-5">
                        <h3 className="mb-5 font-semibold text-[1.2rem]"><span className="text-blue-300 font-semibold">Nonintrusive</span> Ads</h3>
                        We'll try to not make you want to rip your hair out.
                        <br /><br />
                        😛 😛 😛
                    </div>
                </div>
            </div>
        </div>

    </div>;
}

export default WhyUseTimanity;