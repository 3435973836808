const ChangesToThisPrivacyPolicy = () => {
    return <div>
        <h1 className="text-[1.2rem] lg:text-[2rem] mb-5">Changes to this Privacy Policy</h1>
        <p className="my-5">
            We may update Our Privacy Policy from time to time.
            We will notify You of any changes by posting the new Privacy Policy on this page.
        </p>
        <p className="my-5">
            We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
        </p>
        <p className="my-5">
            You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
        </p>
    </div>;
}

export default ChangesToThisPrivacyPolicy;