import React from "react";
import { Link } from "react-router-dom";

const GameThumb = ({name, route, logo, addClasses}) => {
    return (
        <Link to={route} className="w-[45%] md:w-[33%] lg:w-[20%] xl:w-[16%] mx-2">
            <div className="rounded-3xl px-6 pt-10 pb-2 hover:p-2 hover:pb-1 ease-in-out duration-200">
                <img className={`w-[100%] rounded-3xl ${ addClasses }`} src={logo} alt={name}></img>
            </div>
            <p className="text-center ease-in-out duration-300 text-sm">{name}</p>
        </Link>
    );
}

export default GameThumb;