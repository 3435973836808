import { app } from "./models/db/firebase";
import React from 'react';
import GameLanding from './components/GamesLanding/GameLanding';
import Game2048 from './components/Games/Game2048/Game2048'
import ColorFlood from './components/Games/ColorFlood/ColorFlood';
import LightsOut from './components/Games/LightsOut/LightsOut';
import SnakeGame from './components/Games/SnakeGame/SnakeGame';
import {Nav, Tabbar} from './components/Nav/Nav';
import useNavigation from './hooks/useNavigation';
import AboutUs from './components/AboutUs/AboutUs';
import Landing from './components/Landing/Landing';
import Projects from './components/Projects/Projects';
import Contact from './components/Contact/Contact';
import CreditsPage from './components/CreditsPage/CreditsPage';
import NotFound404 from './components/Utilities/Pages/NotFound404';
import PrivacyPolicy from './components/InfoPages/PrivacyPolicy/PrivacyPolicy';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AuthComplete from './components/Account/AuthComplete';
import AppUrlListener from './AppUrlListener';


const App = () => {
    const { currentRoute, setCurrentRoute } = useNavigation();

    return (
        <div className='min-h-screen bg-[#171717] selection:bg-blue-300/[0.3]'>
            <Router>
                <Nav
                    currentRoute={currentRoute}
                    setCurrentRoute={setCurrentRoute}
                />
                <AppUrlListener />
                <Routes>
                    <Route path="/" element={<Landing />} />
                    {/* <Route path="/login" element={<Login />} /> */}
                    <Route path="/games" element={<GameLanding />} />
                    <Route path="/about" element={<AboutUs />} />
                    {/* <Route path="/projects" element={<Projects />} /> */}
                    <Route path="/about" element={<AboutUs />} />
                    {/* <Route path="/games/color-flood" element={<ColorFlood />} />
                    <Route path="/games/2048" element={<Game2048 />} />
                    <Route path="/games/lights-out" element={<LightsOut />} />
                    <Route path="/games/snake-game" element={<SnakeGame />} /> */}
                    <Route path="/privacy-policy/*" element={<PrivacyPolicy />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/__/auth/callback" element={<AuthComplete />} />
                    <Route status={404} path="*" element={<NotFound404 />} />
                    {/* <Route path="/credits-page" element={<CreditsPage />} /> */}
                </Routes>
                <Tabbar
                    currentRoute={currentRoute}
                    setCurrentRoute={setCurrentRoute}
                />
            </Router>
            {/* <CookieSettings /> */}
        </div>
    );
}

export default App;