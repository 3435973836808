const LinkToOtherWebsites = () => {
    return <div>
        <h1 className="text-[1.2rem] lg:text-[2rem] mb-5">Links to Other Websites</h1>
        <p className="my-5">
            Our Service may contain links to other websites that are not operated by Us.
            If You click on a third party link, You will be directed to that third party's site.
            We strongly advise You to review the Privacy Policy of every site You visit.
        </p>
        <p className="my-5">
            We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
        </p>
    </div>;
}

export default LinkToOtherWebsites;