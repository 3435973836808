const paths = [
    [
        {coords: [-1, 1], dir: 0},
        {coords: [-1, 1], dir: 0},
        {coords: [0, 1], dir: 0},
        {coords: [0, 1], dir: 1},
        {coords: [0, 2], dir: 1},
        {coords: [0, 3], dir: 1},
        {coords: [0, 3], dir: 0},
        {coords: [1, 3], dir: 0},
        {coords: [2, 3], dir: 0},
        {coords: [2, 3], dir: 1},
        {coords: [2, 4], dir: 1},
        {coords: [2, 5], dir: 1},
    ],
    [
        {coords: [-1, 1], dir: 0},
        {coords: [-1, 1], dir: 0},
        {coords: [0, 1], dir: 0},
        {coords: [0, 1], dir: 1},
        {coords: [0, 2], dir: 1},
        {coords: [0, 3], dir: 1},
        {coords: [0, 3], dir: 0},
        {coords: [1, 3], dir: 0},
        {coords: [2, 3], dir: 0},
        {coords: [2, 3], dir: 3},
        {coords: [2, 2], dir: 3},
        {coords: [2, 1], dir: 3},
        {coords: [2, 1], dir: 0},
        {coords: [3, 1], dir: 0},
        {coords: [3, 1], dir: 1},
        {coords: [3, 2], dir: 1},
        {coords: [3, 2], dir: 0},
        {coords: [4, 2], dir: 0},
        {coords: [5, 2], dir: 0},
    ],
    [
        {coords: [-1, 1], dir: 0},
        {coords: [-1, 1], dir: 0},
        {coords: [0, 1], dir: 0},
        {coords: [0, 1], dir: 1},
        {coords: [0, 2], dir: 1},
        {coords: [0, 3], dir: 1},
        {coords: [0, 3], dir: 0},
        {coords: [1, 3], dir: 0},
        {coords: [2, 3], dir: 0},
        {coords: [2, 3], dir: 3},
        {coords: [2, 2], dir: 3},
        {coords: [2, 1], dir: 3},
        {coords: [2, 1], dir: 0},
        {coords: [3, 1], dir: 0},
        {coords: [3, 1], dir: 3},
        {coords: [3, 0], dir: 3},
        {coords: [3, -1], dir: 3},
    ],
    [
        {coords: [3, -1], dir: 1},
        {coords: [3, -1], dir: 1},
        {coords: [3, 0], dir: 1},
        {coords: [3, 1], dir: 1},
        {coords: [3, 2], dir: 1},
        {coords: [3, 2], dir: 0},
        {coords: [4, 2], dir: 0},
        {coords: [5, 2], dir: 0},
    ],
    [
        {coords: [3, -1], dir: 1},
        {coords: [3, -1], dir: 1},
        {coords: [3, 0], dir: 1},
        {coords: [3, 1], dir: 1},
        {coords: [3, 2], dir: 1},
        {coords: [3, 2], dir: 2},
        {coords: [2, 2], dir: 2},
        {coords: [2, 2], dir: 2},
        {coords: [2, 2], dir: 3},
        {coords: [2, 1], dir: 3},
        {coords: [2, 1], dir: 0},
        {coords: [3, 1], dir: 0},
        {coords: [3, 1], dir: 1},
        {coords: [3, 2], dir: 1},
        {coords: [3, 2], dir: 2},
        {coords: [2, 2], dir: 2},
        {coords: [2, 2], dir: 1},
        {coords: [2, 3], dir: 1},
        {coords: [2, 4], dir: 1},
        {coords: [2, 5], dir: 1},
    ],
    [
        {coords: [5, 4], dir: 2},
        {coords: [5, 4], dir: 2},
        {coords: [4, 4], dir: 2},
        {coords: [4, 4], dir: 3},
        {coords: [4, 4], dir: 2},
        {coords: [4, 4], dir: 3},
        {coords: [4, 4], dir: 2},
        {coords: [4, 4], dir: 0},
        {coords: [5, 4], dir: 0},
    ]
];

let x = [
    {coords: [5, 4], dir: 2},
    {coords: [5, 4], dir: 2},
    {coords: [4, 4], dir: 2},
    {coords: [4, 4], dir: 3},
    {coords: [4, 4], dir: 2},
    {coords: [4, 4], dir: 3},
    {coords: [4, 4], dir: 2},
    {coords: [4, 4], dir: 0},
    {coords: [5, 4], dir: 0},
]

class PacBlobHelper {
    constructor() {
        this.paths = paths;
        this.currentPath = Math.abs(Math.round(Math.random() * this.paths.length - 1));
        this.pathIndex = 0;
        this.hide = false;
    }

    getXCoord () {
        return this.paths[this.currentPath][this.pathIndex].coords[0];
    }

    getYCoord () {
        return this.paths[this.currentPath][this.pathIndex].coords[1];
    }

    getDir () {
        return this.paths[this.currentPath][this.pathIndex].dir;
    }

    nextPoint () {
        if (this.pathIndex + 1 < this.paths[this.currentPath].length) {
            this.hide = false;
            this.pathIndex += 1;
        } else {
            this.hide = true;
            let next = Math.abs(Math.round(Math.random() * this.paths.length - 1));
            
            while (next === this.currentPath && next < this.paths.length) {
                next = Math.abs(Math.round(Math.random() * this.paths.length - 1));
            }

            this.currentPath = next;
            this.pathIndex = 0;
        }

        return this;
    } 
}

export default PacBlobHelper;