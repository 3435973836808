const RetentionOfYourPersonalData = () => {
    return <div>
        <h2 className="text-[1.1rem] lg:text-[1.8rem] mb-5">Retention of Your Personal Data</h2>
        <p className="my-5">
            The Initiative will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy.
            We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
        </p>
        <p className="my-5">
            The Initiative will also retain Usage Data for internal analysis purposes.
            Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
        </p>
    </div>;
}

export default RetentionOfYourPersonalData;