import Logo2048 from '../Games/Game2048/img/Game 2048 Logo.svg'
import LogoColorFlood from '../Games/ColorFlood/img/Color Flood Logo.svg'
import LogoLightsOut from '../Games/LightsOut/img/Lights Out Logo.svg'
import LogoSnakeGame from '../Games/SnakeGame/img/Snake Game Logo.svg'

const games = [
    {
        name: "2048",
        route: "/games/2048",
        logo: Logo2048,
        addClasses: "shadow-[inset_0px_0px_20px_5px_rgba(167,139,250,1)] hover:shadow-[inset_0px_0px_30px_10px_rgba(251,113,133,1)] transition-all duration-150 ease-linear"
    },
    {
        name: "Color Flood",
        route: "/games/color-flood",
        logo: LogoColorFlood,
        addClasses: "shadow-[inset_0px_0px_20px_5px_rgba(165,243,252,1)] hover:shadow-[inset_0px_0px_30px_10px_rgba(236,72,153,1)] transition-all duration-150 ease-linear"
    },
    {
        name: "Lights Out",
        route: "/games/lights-out",
        logo: LogoLightsOut,
        addClasses: "shadow-[inset_0px_0px_20px_5px_rgba(253,186,116,1)] hover:shadow-[inset_0px_0px_30px_10px_rgba(223,51,192,1)] transition-all duration-150 ease-linear"
    },
    {
        name: "Snake",
        route: "/games/snake-game",
        logo: LogoSnakeGame,
        addClasses: "shadow-[inset_0px_0px_20px_5px_rgba(23,186,116,1)] hover:shadow-[inset_0px_0px_30px_10px_rgba(150,230,23,1)] transition-all duration-150 ease-linear"
    },
];

export default games;