import { useLocation } from "react-router-dom";
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { getUser } from "../../models/local/User";
import { useEffect } from "react";
import TimanityLogo from "../../assets/Timanity MiniGames Logo.svg";

const auth = getAuth();

const AuthComplete = () => {
    useEffect(() => {
        (async () => {
            if (isSignInWithEmailLink(auth, window.location.href)) {
                const email = (await getUser()).email;
                // if (!email) {
                //     // User opened the link on a different device. To prevent session fixation
                //     // attacks, ask the user to provide the associated email again. For example:
                //     email = window.prompt('Please provide your email for confirmation');
                // }
                // The client SDK will parse the code from the link for you.
                const result = await signInWithEmailLink(auth, email, window.location.href)
                // Clear email from storage.
                // You can access the new user via result.user
                // Additional user info profile not available via:
                // result.additionalUserInfo.profile == null
                // You can check if the user is new or existing:
                // result.additionalUserInfo.isNewUser
                alert(JSON.stringify(result));
                alert("done");
            }
        })();
    }, [])

    return <div className="w-[100vw] h-[100vh] flex flex-col justify-center">
        <div className={`w-[80%] max-w-[300px] text-center mx-auto my-10 border-[3px] rounded-full p-14 drop-shadow-[0px_0px_10px_#BFA5FA] shadow-[inset_0px_0px_10px_5px_#BFA5FA] animate-pulse`}>
            <img src={TimanityLogo} className="w-[100%] h-auto md:max-w-[450px] block mx-auto my-auto drop-shadow-[0px_0px_30px_rgba(59,130,246,1)]" alt="Timanity MiniGames Logo" />
        </div>
        <p className="text-[0.7rem]">{JSON.stringify(window.location.href)}</p>
        {/* <p>{JSON.stringify(auth.currentUser)}</p> */}
    </div>
}

export default AuthComplete;